var serviceToggler = {
	init: function () {

        $('.button.show').on('click', function(e) {
            $('.services_column').toggleClass("show"); 
            $('.button.show').toggleClass("hide"); 
            $('.button.hidden').toggleClass("hide"); 
            e.preventDefault();
        });
        $('.button.hidden').on('click', function(e) {
            $('.services_column').toggleClass("show"); 
            $('.button.show').toggleClass("hide"); 
            $('.button.hidden').toggleClass("hide"); 
            e.preventDefault();
        });
    }
};

serviceToggler.init();
